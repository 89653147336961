<template>
  <div class="changeGoods">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="changeGoods-content">
      <el-form
        :model="goodsForm"
        :rules="rules"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="goodsForm.goodsName"
            style="width: 240px"
            placeholder="请选择商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="编辑商品"
          prop="is_edit"
        >
          <el-select v-model="goodsForm.is_edit" filterable placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片" class="shopInfo-item" prop="goodsImg">
          <div style="display: flex">
            <el-dialog :visible.sync="dialogVisible" :modal="false" width="40%">
              <img width="100%" :src="imgUrl + imageUrl" alt="" />
            </el-dialog>
            <el-upload
              :action="httpUrl + 'api/v1/images'"
              name="image"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="uploadURL"
            >
              <img v-if="imageUrl" :src="imgUrl + imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="imgTips">
              <div class="seeImg" @click="bigImg">浏览</div>
              <p style="margin-top: 10px">
                <span style="padding-right: 8px">*</span>图片大小：小于500KB
              </p>
              <p style="text-indent: 1em">图片尺寸：690px*316px</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="默认上架盒子数量" prop="goodsNum">
          <el-input-number
            v-model="goodsForm.goodsNum"
            label="描述文字"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最高上架" prop="max_quantity">
          <el-input-number
            v-model="goodsForm.max_quantity"
            label="描述文字"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <div class="tips-text" style="margin-top:-10px;">注：设置商品库存最高上架数量，设置为0时表示不限制</div>
        <el-form-item label="取货时间" required>
          <span style="display: inline-block;margin-right:10px;">开始</span>
          <el-time-picker
            v-model="goodsForm.goodsTimeStart"
             :picker-options="{
              selectableRange: '07:00:00 - 23:59:00',
            }"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="开始时间"
            size="small"
            style="width:150px"
            @change="startTimer"
          >
          </el-time-picker>
          <span style="width: 20px; text-align: center; display: inline-block">-</span>
          <el-select
            v-if="is_auto_good==0"
            v-model="goodsForm.is_next"
            size="small"
            placeholder="今天/明天"
            style="width:75px;margin-right:10px;"
            @change="changeisNext"
          >
            <el-option
              v-for="(item, index) in isNextList"
              :key="index"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
          <span style="display: inline-block;margin-right:10px;">结束</span>
          <el-time-picker
            :editable="false"
            :clearable="false"
            v-model="goodsForm.goodsTimeEnd"
            :picker-options="{
              selectableRange: '00:10:00 - 23:59:00',
            }"
            value-format="HH:mm"
            format="HH:mm"
            size="small"
            placeholder="结束时间"
            style="width:150px"
            @change="endTimer"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item
          label="默认上架时间"
          prop="shell_type"
        >
          <el-select v-model="goodsForm.shell_type" filterable placeholder="请选择">
            <el-option
              v-for="item in shellTypeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="时间区间" required v-if="goodsForm.shell_type==2">
          <el-time-picker
            v-model="goodsForm.shell_time_start"
            :picker-options="{
              selectableRange: '07:00:00 - 23:59:00',
            }"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="开始时间"
            size="small"
            @change="changeShellTimeStart"
          >
          </el-time-picker>
          <span style="width: 20px; text-align: center; display: inline-block"
            >-</span
          >
          <el-time-picker
            v-model="goodsForm.shell_time_end"
            :picker-options="{
              selectableRange: '07:00:00 - 23:59:00',
            }"
            value-format="HH:mm"
            format="HH:mm"
            size="small"
            placeholder="结束时间"
            @change="changeShellTimeEnd"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="指定时间" required v-if="goodsForm.shell_type==3">
          <el-time-picker
            v-model="goodsForm.shell_time"
           :picker-options="{
              selectableRange: '07:00:00 - 23:59:00',
            }"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="指定时间"
            size="small"
            @change="changeShellTime"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: center">
          <el-button
            type="primary"
            size="small"
            style="background: #cd0550; border: none"
            @click="changeGoods"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
import { changeGoodsInfo_api ,changeGoodsInfo_api1} from "@/api/goods.js"
//阿里云直传
import {client} from "@/utils/alioss.js"
export default {
  data() {
    return {
      imgUrl: imgUrl,
      httpUrl: httpUrl,
      goodsForm: {
        goodsName: "",
        is_edit:1,
        is_next:0,
        goodsImg: "",
        goodsNum: "",
        max_quantity:"",
        goodsTimeStart: "",
        goodsTimeEnd: "",
         shell_type:1,
        shell_time_start:'',
        shell_time_end:'',
        shell_time:'',
      },
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        goodsTimeStart: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        goodsTimeEnd: [{ required: true, message: "请选择结束时间", trigger: "change" }],
        goodsNum: [{ required: true, message: "请选择商品数量", trigger: "blur" }],
        max_quantity: [{ required: true, message: "请选择最高上架", trigger: "blur" }],
        shell_type: [{ required: true, message: "请选择", trigger: "blur" }],
        goodsImg: [{ required: true, message: "请选择商品图片", trigger: "blur" }],
      },
      tk: "",
      imageUrl: "",
      goodsInfo: "",
      dialogVisible:false,
      typeOptions: [{
        id: 0,
        name: '不可编辑'
      }, {
        id: 1,
        name: '可编辑'
      }],
      shellTypeOptions:[{
        id: 1,
        name: '取货时间结束后'
      }, {
        id: 2,
        name: '指定时间区间范围内'
      }, {
        id: 3,
        name: '指定时间'
      }],
      ultimateEnd:'',
      ultimateStar:'',
      is_auto_good:null,
      isNextList: [{
        id: 0,
        title: '今天'
      }, {
        id: 1,
        title: '明天'
      }],
    }
  },
  methods: {
     uploadURL(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.imageUrl=res.name
      })
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    //设置时间范围
    startTimer() {
      
    },
    changeisNext() {
      this.goodsForm.goodsTimeEnd = null
    },
    endTimer() {
      if (this.goodsForm.is_next == 1) {
        // 隔夜-明天结束
        this.ultimateEnd=this.goodsForm?.goodsTimeEnd?.substr(0,2)*60+this.goodsForm?.goodsTimeEnd?.substr(3,2)*1
        this.ultimateStar=this.goodsForm?.goodsTimeStart?.substr(0,2)*60+this.goodsForm?.goodsTimeStart?.substr(3,2)*1
        if (this.ultimateEnd > this.ultimateStar) {
          this.goodsForm.goodsTimeEnd = null
          this.$message.error("取货结束时间必须小于取货开始时间")
          return
        }
      } else {
        const aaa = '23:50'
        this.ultimateEnd=this.goodsForm?.goodsTimeEnd?.substr(0,2)*60+this.goodsForm?.goodsTimeEnd?.substr(3,2)*1
        this.ultimateStar=this.goodsForm?.goodsTimeStart?.substr(0,2)*60+this.goodsForm?.goodsTimeStart?.substr(3,2)*1
        const endA=aaa.substr(0,2)*60+aaa.substr(3,2)*1
        if(this.ultimateEnd<=this.ultimateStar){
          this.goodsForm.goodsTimeEnd=null
          this.$message.error("取货结束时间不能小于取货开始时间")
          return
        }
        if(this.ultimateEnd>endA){
          this.goodsForm.goodsTimeEnd=null
          this.$message.error("取货结束时间不能大于23:50")
          return
        }
      }
    },
    //修改商品信息
    async changeGoods() {
      if(this.goodsForm.shell_type==2){
        if(!this.goodsForm.shell_time_start || !this.goodsForm.shell_time_end){
          this.$message.error("时间区间开始时间和结束时间必填")
          return
        }
      }
      if(this.goodsForm.shell_type==3&&!this.goodsForm.shell_time){
        this.$message.error("指定时间必填")
        return
      }
      this.timeCount()

      if(this.goodsForm.is_next==0){
        if(this.ultimateStar>this.ultimateEnd){
          this.$message.error("结束时间必须大于开始时间")
          return
        }

        if(this.ultimateEnd-this.ultimateStar<30){
          this.$message.error("结束时间距离开始时间必须大于30分钟")
          return
        }
      }
      if(local.get("num")==1){
        let res = await changeGoodsInfo_api1({
        good_id: this.goodsInfo.id,
        good_name: this.goodsForm.goodsName,
        good_image: this.imageUrl,
        is_edit: this.goodsForm.is_edit,
        is_next:this.goodsForm.is_next,
        good_quantity: this.goodsForm.goodsNum,
        max_quantity:this.goodsForm.max_quantity,
        pickup_time_start: this.goodsForm.goodsTimeStart
          ? this.goodsForm.goodsTimeStart
          : this.goodsInfo.goods_configuration_one.pickup_time_start,
        pickup_time_end: this.goodsForm.goodsTimeEnd
          ? this.goodsForm.goodsTimeEnd
          : this.goodsInfo.goods_configuration_one.pickup_time_end,
        shell_type:this.goodsForm.shell_type,
        shell_time_start:this.goodsForm.shell_time_start,
        shell_time_end:this.goodsForm.shell_time_end,
        shell_time:this.goodsForm.shell_time,
      });

      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.$router.push("/goods1")
      }
      }else{
        let res = await changeGoodsInfo_api({
        good_id: this.goodsInfo.id,
        good_name: this.goodsForm.goodsName,
        good_image: this.imageUrl,
        is_edit:this.goodsForm.is_edit,
        is_next:this.goodsForm.is_next,
        good_quantity: this.goodsForm.goodsNum,
        max_quantity:this.goodsForm.max_quantity,
        pickup_time_start: this.goodsForm.goodsTimeStart
          ? this.goodsForm.goodsTimeStart
          : this.goodsInfo.goods_configuration_one.pickup_time_start,
        pickup_time_end: this.goodsForm.goodsTimeEnd
          ? this.goodsForm.goodsTimeEnd
          : this.goodsInfo.goods_configuration_one.pickup_time_end,
        shell_type:this.goodsForm.shell_type,
        shell_time_start:this.goodsForm.shell_time_start,
        shell_time_end:this.goodsForm.shell_time_end,
        shell_time:this.goodsForm.shell_time,
      })

      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.$router.push("/goods")
      }
      }
    },
     shellType(e){
      if(e==1){
        this.goodsForm.shell_time_start=''
        this.goodsForm.shell_time_end=''
        this.goodsForm.shell_time=''
      }else if(e==2){
        this.goodsForm.shell_time=''
      }else{
        this.goodsForm.shell_time_start=''
        this.goodsForm.shell_time_end=''
      }
    },
    changeShellTimeStart(){
      this.timeCount()
      // 区间开始时间
      const shellStart=this.goodsForm.shell_time_start.substr(0,2)*60+this.goodsForm.shell_time_start.substr(3,2)*1
      // 时间段需要设置两个具体时间，该时间不能和取货时间有交叉
      if(shellStart>this.ultimateStar&&shellStart<this.ultimateEnd){
        this.goodsForm.shell_time_start=null
        this.$message.error("时间区间开始时间不能在取货时间中")
        return
      }
    },
    changeShellTimeEnd(){
      this.timeCount()
      // 区间开始时间
      const shellStart=this.goodsForm?.shell_time_start?.substr(0,2)*60+this.goodsForm?.shell_time_start?.substr(3,2)*1
      // 区间结束时间
      const shellEnd=this.goodsForm?.shell_time_end?.substr(0,2)*60+this.goodsForm?.shell_time_end?.substr(3,2)*1
      // 如果时间区间开始时间小于取货开始时间，时间区间结束时间也要小于取货开始时间
      if(shellStart<this.ultimateStar){
        if(shellEnd>this.ultimateStar){
          this.goodsForm.shell_time_end=null
          this.$message.error("时间区间结束时间必须小于取货开始时间")
          return
        }
      }
      // 如果时间区间开始时间大于取货结束时间，时间区间结束时间也要大于取货结束时间
      if(shellStart>this.ultimateEnd){
        if(shellEnd<this.ultimateEnd){
          this.goodsForm.shell_time_end=null
          this.$message.error("时间区间结束时间必须大于取货结束时间")
          return
        }
      }
      if(shellEnd<shellStart){
        this.goodsForm.shell_time_end=null
        this.$message.error("时间区间结束时间必须大于时间区间开始时间")
        return
      }
      // 时间段需要设置两个具体时间，该时间不能和取货时间有交叉
      if(shellEnd>this.ultimateStar&&shellEnd<this.ultimateEnd){
        this.goodsForm.shell_time_end=null
        this.$message.error("时间区间结束时间不能在取货时间中")
        return
      }
    },
    changeShellTime(e){
      this.timeCount()
      // 指定时间
       const shell=this.goodsForm.shell_time.substr(0,2)*60+this.goodsForm.shell_time.substr(3,2)*1
      // 指定时间需要设置个具体时间，该时间不能在取货时间中
      if(shell>this.ultimateStar&&shell<this.ultimateEnd){
        this.goodsForm.shell_time=null
        this.$message.error("指定时间不能在取货时间中")
        return
      }
    },
    timeCount(){
      this.ultimateEnd=this.goodsForm?.goodsTimeEnd?.substr(0,2)*60+this.goodsForm?.goodsTimeEnd?.substr(3,2)*1
      this.ultimateStar=this.goodsForm?.goodsTimeStart?.substr(0,2)*60+this.goodsForm?.goodsTimeStart?.substr(3,2)*1
    }
  },
  created() {
    this.tk = local.get("tk")
    let goodsInfoA = local.get("goodsInfo")
    this.goodsInfo = JSON.parse(goodsInfoA)
    console.log('this.goodsInfo',this.goodsInfo)
    this.goodsForm.goodsName = this.goodsInfo.good_name
    this.goodsForm.is_edit=this.goodsInfo.is_edit
    this.goodsForm.is_next = this.goodsInfo.is_next
    this.is_auto_good = this.goodsInfo.is_auto_good
    this.imageUrl = this.goodsInfo.good_image
    this.goodsForm.goodsTimeStart = this.goodsInfo.goods_configuration_one.pickup_time_start
    this.goodsForm.goodsTimeEnd = this.goodsInfo.goods_configuration_one.pickup_time_end
    this.goodsForm.goodsNum = this.goodsInfo.goods_configuration_one.good_quantity;
    this.goodsForm.max_quantity=this.goodsInfo.goods_configuration_one.max_quantity;
    this.goodsForm.shell_type=this.goodsInfo.goods_configuration_one.shell_type;
    this.goodsForm.shell_time_start=this.goodsInfo.goods_configuration_one.shell_time_start;
    this.goodsForm.shell_time_end=this.goodsInfo.goods_configuration_one.shell_time_end;
    this.goodsForm.shell_time=this.goodsInfo.goods_configuration_one.shell_time;
  }
}
</script>

<style lang="less" scoped>
.changeGoods {
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 30px;
  box-sizing: border-box;
  .changeGoods-content {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 346px;
  height: 160px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 346px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar {
  width: 346px;
  height: 160px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
.tips-text{
    width: 100%;
    font-size: 12px;
    color:#999;
    font-weight: 500;
    line-height: 20px;
    padding-left: 140px;
    margin: 8px 0px 18px 0px;
  }
</style>